@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.container {
  @include column;
  align-items: center;
  gap: 60px;

}

.items {
  display: flex;
  justify-content: center;
  gap: 30px;

  .item {
    padding: 25px;
    width: 30%;
    height: auto;
    color: rgba(38, 38, 38, 0.80);

    text-align: center;
    font-family: Cabin;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    @include column;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    border: 1px solid #E8EFF5;
    background: #FFF;
    text-align: center;

    /* Shadow */
    box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);

    img {
      width: 80px;
      height: 80px;
    }

    ul {
      @include column
    }
  }
}

//start mobile
.mobileContainer {
  padding: 0 24px;


  .items {
    flex-direction: column;
   .item {
     width: 100%;
   }
  }
}

//end Mobile

