.logoContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  img{
    width: 100px;
    height: 30px;
  }
}