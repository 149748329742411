@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.franchiseCalculatorContainer {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  gap: 192px;

  .sendFormModal {
    @include column;
    gap: 50px;
    margin: 0 auto 0;
    padding: 30px;
    color: var(--Dark, #262626);
    text-align: center;
    font-family: Cabin;
    font-size: 18px;
    font-style: normal;

    .closeContainer {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }

  }

  .calculatorInfo {
    @include column;
    gap: 20px;

    .item {
      display: flex;
      justify-content: flex-start;
align-items: center;
      gap: 20px;

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  .sendForm {
    position: relative;
    width: 600px;
    padding: 40px 60px;
    @include column-center;
    gap: 60px;
    border-radius: 20px;
    border: 1px solid var(--Light-Blue, #E8EFF5);
    background: #FFF;
    box-shadow: 2px 2px 32px 0px rgba(147, 164, 171, 0.08), -2px 0px 32px 0px rgba(147, 164, 171, 0.08);

    .imgContainer {
      position: absolute;
      bottom: 0px;
      right: -185px;
      z-index: -1;

      img {
        width: 600px;
        height: auto;

      }
    }


    .formContainer {
      @include column;

      gap: 20px;
      .buttonGroup{
        display: flex;
        width: 100%;
        padding: 8px;
        align-items: flex-start;
        border-radius: 8px;
        background: var(--Brand-Tints-Blue-0, #F3F7FA);
        gap: 5px;
        button{
          display: flex;
          padding: 8px 10px;
          justify-content: center;
          align-items: center;
          flex: 1 0 0;
          border: 0;

        }
        .selected{
          border-radius: 4px;
          border: 1px solid #E8EFF5;
          background: #FFF;
        }

      }
      .formTitle {
        @include column-center;
        gap: 20px;
      }

      .form {
        width: 100%;
        @include column-center;
        gap: 12px;

        label {
          @include column;
          gap: 10px;
          width: 100%;
        }

        .input1 {
          width: 100%;
          height: 56px;
          padding: 20px 16px;
          border-radius: 8px;
          border: 1px solid var(--brand-2, #FFC83B);
          &:focus{
            outline: none;
          }
        }
        .input2 {
          width: 100%;
          height: 56px;
          padding: 20px 16px;
          border-radius: 8px;
          border: 1px solid #D4D4D4;
          &:focus{
            outline: none;
          }
        }

        button {
          width: 100%;
          height: 56px;
          border-radius: 50px;
          background: #FAE95B;
          border: 0;
        }
      }
    }
  }
}


//start mobile
.mobileFranchiseCalculatorContainer {
  padding: 50px 0;
  @include column;
  justify-content: space-evenly;
  gap: 20px;
  .sendFormModal{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
   .modalTitle{
     display: revert;
     align-items: center;
     justify-content: center;
     gap: 5px;
     h2{
       color: var(--Dark, #262626);
       text-align: center;
       font-family: Cabin;
       font-size: 18px;
       font-style: normal;
       font-weight: 700;
       line-height: 140%; /* 25.2px */
     }
     .closeContainer {
       display: flex;
       justify-content: flex-end;
       cursor: pointer;
     }
   }

  }
  .calculatorInfo {
    @include column;
    gap: 20px;
    align-items: flex-start;
    padding: 0 10%;

    h1 {
      font-size: 28px;
    }

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      gap: 20px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .sendForm {
    @include column;
    width: 100%;
    height: 382px;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    //background-color: #FAE95B;

    .imgContainer {
      display: none;
    }

    .formContainer {
      padding: 24px;
      border-radius: 20px;
      border: 0.583px solid var(--Light-Blue, #E8EFF5);
      background: #FFF;
      box-shadow: 1.166px 1.166px 18.661px 0px rgba(147, 164, 171, 0.08), -1.166px 0px 18.661px 0px rgba(147, 164, 171, 0.08);
      width: 100%;
      height: auto;
      .buttonGroup{
        display: flex;
        width: 100%;
        padding: 8px;
        align-items: flex-start;
        border-radius: 8px;
        background: var(--Brand-Tints-Blue-0, #F3F7FA);
        gap: 5px;
        button{
          display: flex;
          padding: 8px 10px;
          justify-content: center;
          align-items: center;
          flex: 1 0 0;
          border: 0;

        }
        .selected{
          border-radius: 4px;
          border: 1px solid #E8EFF5;
          background: #FFF;
        }

      }
      .formTitle {
        display: flex;
        gap: 20px;

        h3 {
          font-size: 16px;
        }
      }

      .form {
        @include column-center;
        gap: 12px;

        label {
          @include column;
          gap: 10px;
        }

        input {
          width: 300px;
          height: 56px;
          padding: 20px 16px;
          border-radius: 8px;
          border: 1px solid var(--brand-2, #FFC83B);
        }

        button {
          width: 300px;
          height: 56px;
          border-radius: 50px;
          background: #FAE95B;
          border: 0;
        }
      }
    }
  }
}

// end mobile