@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;

    .section {
      display: flex;
      background-color: $yellow;
      width: 100%;
      align-items: center;
      justify-content: space-around;
      padding-top: 60px;
       .earnMoneyForm{
         @include column-center;
         width: 45%;
         gap: 10px;
         .titleContainer {
           display: flex;
           align-items: center;
           gap: 20px;
         }
         .form {
           display: flex;
           justify-content: center;
           align-items: center;
           width: 100%;
           height: 409px;
           flex-shrink: 0;
           border-radius: 40px;
           border: 1px solid var(--Light-Blue, #E8EFF5);
           background: #FFF;

           /* Shadow */
           box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);

           .sendForm {
             @include column;
             gap: 120px;
             padding: 60px;

             .rows {
               gap: 32px;
               @include column;

               .firstRowItems {
                 display: flex;
                 gap: 60px;

                 input {
                   width: 50%;
                   height: 30px;
                   padding: 0 10px;
                 }
               }

               .rowItems {
                 gap: 32px;
                 @include column;

                 .btnContainer {
                   display: flex;
                   justify-content: center;

                   button {
                     max-width: 427.2px;
                     width: 100%;
                     height: 22.167px;
                     flex-shrink: 0;
                     color: #262626;
                     text-align: center;
                     font-family: Roboto;
                     font-size: 18px;
                     font-style: normal;
                     font-weight: 600;
                     line-height: normal;
                     height: 40px;
                     @include center
                   }
                 }

               }
               //}


               input {
                 height: 35px;
                 border-top: 0;
                 border-right: 0;
                 border-left: 0;
                 border-bottom: 1px solid #D4D4D4;
                 padding: 0 10px;

                 &:focus {
                   outline: 0;
                 }
               }

               textarea {
                 border-top: 0;
                 border-right: 0;
                 border-left: 0;
                 border-bottom: 1px solid #D4D4D4;
                 padding: 0 10px;

                 &:focus {
                   outline: 0;
                 }
               }

               button {
                 height: 50px;
                 background-color: $yellow;
                 @include buttonStyle
               }
             }
           }
         }
       }
      .imgContainer {
        width: 45%;

        img {
          width: auto;
          height: 470px;
        }
      }


  }


}

//mobile start
.mobileContainer {

  width: 100%;
  height: 100%;
  @include column;
  align-items: center;
  text-align: center;
  background-color: $yellow;

  //.subContainer {
  //  width: 100%;
  //  @include column;
  //  gap: 20px;
  //  padding: 20px 0;
    


    .section {
      display: flex;
      flex-direction: column-reverse;
      .earnMoneyForm{
        @include column-center;
        gap: 20px;
        .titleContainer {
          display: flex;
          justify-content: center;
          gap: 20px;

          h1 {
            font-size: 28px;
          }
        }
        .form {
          flex-shrink: 0;
          border-radius: 40px;
          border: 1px solid var(--Light-Blue, #E8EFF5);
          background: #FFF;
          // margin: 0 20px;

          /* Shadow */
          box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);

          .sendForm {
            @include column;
            gap: 20px;
            padding: 20px;

            .rows {
              gap: 20px;
              @include column;

              .firstRowItems {
                gap: 20px;
                @include column;


              }

              .rowItems {
                @include column;
                gap: 20px;

                .btnContainer {
                  display: flex;
                  justify-content: center;

                  button {
                    width: 300px;
                    // height: 22px;
                    @include center;
                    flex-shrink: 0;
                    color: #262626;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                  }
                }

              }
            }


            input {
              height: 35px;
              border-top: 0;
              border-right: 0;
              border-left: 0;
              border-bottom: 1px solid #D4D4D4;
              padding: 0 10px;

              &:focus {
                outline: 0;
              }
            }

            textarea {
              border-top: 0;
              border-right: 0;
              border-left: 0;
              border-bottom: 1px solid #D4D4D4;
              padding: 0 10px;

              &:focus {
                outline: 0;
              }
            }

            button {
              height: 50px;

              background-color: $yellow;
              @include buttonStyle
            }
          }
        }
      }
      .imgContainer {
        padding: 2px 5px;

        img {
          width: 100%;
        }
      }


    }

  //}

}

//mobile end