@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.container {
display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: 0 100px;
.items{
  display: flex;
  justify-content: center;
  gap: 40px;
  .item {
    @include column;
    gap: 32px;

    & > div {
      img {
        width: 100%;
        height: 263px;
      }
    }

    img {
      width: 100%;
      height: 560px;
    }
  }
}

}

//mobile start
.mobileContainer {
  @include column;
  padding: 0 5px;
  width: 100%;
  height: 100%;
  gap: 20px;
  .items{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  .item {
    @include column;

    &:nth-child(2) {
      @include column;
      gap: 20px;

      img {
        width: 100%;
        height: 150px;
      }
    }

    img {
      width: 100%;
      height: 384px;
    }
  }}
}

//mobile end