@import '../../../assets/styles/variables';
@import "../../../assets/styles/mixins";

.aboutMimoContainer {
  @include column;
  font-family: Cabin, serif;
  font-style: normal;

  .sendFormModal {
    @include column;
    gap: 50px;
    margin: 0 auto 0;
    //padding: 30px;
    color: var(--Dark, #262626);
    text-align: center;
    font-family: Cabin;
    font-size: 18px;
    font-style: normal;
    width: 100%;
    .modalTitle{
      .closeContainer {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
      }

    }


  }
  .contentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 192px 0 160px;

    @media (max-width: 1024px) {
      padding: 40px 5% 0 5%;
    }

    .informationContainer {
      @include column;
      gap: 32px;

      h1 {
        //color: var(--Dark, #262626);
        color: #262626;
        font-size: 64px;
        font-weight: 700;
        line-height: 130%;
        /* 83.2px */
        letter-spacing: 1.28px;
      }

      p {
        color: rgba(38, 38, 38, 0.80);
        font-size: 18px;
        font-weight: 400;
        line-height: 170%;
      }

      .buttonContainer {
        display: flex;
        gap: 20px;

        button {
          width: 200px;
          height: 56px;
          @include buttonStyle;

          a{
            text-decoration: none;
            color:black
          }
        }

        button:nth-child(1) {
          background: $yellow;
        }
        button:nth-child(2) {
          background: transparent;
          border: 1px solid #404040;
        }
      }
    }

    .imageContainer {
      img {
        width: 618px;
        height: 718px;
      }
    }
  }

  .footerContainer {
    width: 100%;
    display: flex;
    background: #202632;
    height: 350px;

    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 192px 0 160px;

      @media (max-width: 1024px) {
        padding: 40px 5% 0 5%;
      }
      
      .footerInformationContainer {
        @include column;
        width: 46%;

        h1 {
          color: #F6F6F7;
          font-size: 44px;
          font-weight: 600;
          line-height: 140%;
        }

        p {
          color: rgba(246, 246, 247, 0.80);
          font-size: 18px;
          font-weight: 400;
          line-height: 170%;
        }
      }

      .animationContainer {
        width: 45%;
        display: flex;
        align-items: center;
        gap: 24px;


        .item {
          width: 160px;
          height: 126px;
          padding: 15.61px;
          @include column-center;
          gap: 19.2px;
          border-radius: 32px;
          background: rgba(255, 255, 255, 0.99);
          box-shadow: 0 0 9.79592px 0 rgba(60, 77, 84, 0.07);
          transition: all 0.1ms ease-in-out;
          position: relative;

          img {
            width: 65px;
            height: 65px;
          }


        }
      }
    }

  }


  @keyframes changeSizeAndColor {

    0%,
    100% {
      transform: scale(0.9);
    }

    25% {
      transform: scale(1.2);
    }

    50%,
    75% {
      transform: scale(0.9);
    }
  }


  @for $i from 1 through 3 {
    .item:nth-child(#{$i}) {
      animation: changeSizeAndColor $animation-duration infinite;
      animation-delay: $animation-duration / 3 * ($i - 1);
      animation-fill-mode: both;
    }
  }

}


//// Mobile start ///
.mobileAboutMimoContainer {
  .contentContainer {
    @include column;
    padding: 20px;
    .modalTitle{
      width: 100%;
      h2{
        font-size: 18px;
      }
      .closeContainer {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
      }
    }
    .informationContainer {
      h1 {
        font-size: 40px;
      }

      .buttonContainer {
        button {
          width: 50%;
          font-size: 16px;
          font-weight: 600;
          a{
            text-decoration: none;
            color:black
          }
        }
      }
    }

    .imageContainer {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .footerContainer {
    height: auto;

    .container {
      padding: 60px 20px;
      flex-direction: column;gap: 20px;

      .footerInformationContainer {
        width: 100%;
      }

      .animationContainer {

        width: 100%;
      }
    }
  }


  @for $i from 1 through 3 {
    .item:nth-child(#{$i}) {
      // animation: changeSizeAndColorMobile $animation-duration infinite;
      animation-delay: $animation-duration / 3 * ($i - 1);
      animation-fill-mode: both;
    }
  }

}

//// Mobile end ///

@keyframes changeSizeAndColorMobile {
  0% {
    transform: scale(0.9) translateX(-400px);
  }

  20% {
    transform: scale(0.9) translateX(-200px);
  }

  30% {
    transform: scale(0.9) translateX(0);
  }

  40% {
    transform: scale(1.2) translateX(150px);
  }

  50% {
    transform: scale(1.2)  translateX(200px);
  }
  
  60% {
    display: none;

  }

  70% {
    display: none;

  }

  80% {
    display: none;

  }

  90% {
    display: none;
  ;
  }

  100% {
    display: none;

  }
}
