@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.whatOurRidersSay {
  position: relative;
  @include column;
  justify-content: space-between;
  background-color: $colorDark;
  border-radius: 100% 100% 0 0% / 20%;
  padding: 140px 0 40px;
  color: #F6F6F7;
  margin-top: -120px;
  z-index: 1;
  flex-shrink: 0;


  .subWhatOurRidersSay {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}

.section1 {
  width: 35%;
  //@include center;
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: scroll;
  scroll-behavior: smooth;
  font-size: 14px;
  overflow: visible;

  &::-webkit-scrollbar {
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    top: -1rem;
    right: -0.8rem;
    z-index: -1;
    height: 219px;
    width: 219px;
    background-image: url(../../../assets/images/whatOurRidersSay/dots.png);
  }
}

.section2 {
  justify-content: flex-start;
  align-items: flex-start;
  width: 25%;

  h1 {
    margin-bottom: 32px;
  }
}

.navigationButtons {
  width: fit-content;
  display: flex;
  justify-content: center;
  left: 31%;
  position: absolute;
  z-index: 10000;
  bottom: 1%;

  .arrowContainer {
    cursor: pointer;
    @include center;
    width: 32px;
    height: 32px;
    padding: 6px 8px;
    gap: 10px;
    border-radius: 50px;
    background: #FFF;

    &:hover {
      background: darken(#FFF, 5%);
      transform: scale(1.1);
    }

    &:first-child {
      margin-right: 0.5rem;

      img {
        transform: rotate(90deg);
      }
    }

    img {
      transform: rotate(270deg);
    }
  }
}


/// Mobile start ///
.mobileWhatOurRidersSay {
  border-radius: 88% 100% 0 0%/8%;
  padding: 40px 0 40px;
  margin-top: -114px;

  .subWhatOurRidersSay {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    .section2 {
      width: 80%;

      h1 {
        font-size: 28px;
      }

      p {
        font-size: 18px;
      }
    }

    .section1 {
      width: 100%;
      max-width: 90%;
      height: 355px;
      flex-shrink: 0;
      margin-top: 90px;

    }
  }
  .navigationButtons{
    width: fit-content;
    display: flex;
    justify-content: center;
    left: 38%;
    position: absolute;
    z-index: 10000;
    bottom: 2%;
  }
}

/// Mobile end  ///