@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.review {
  @include column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 20px;
  background: #FFF;
  color: #262626;
  box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);
  z-index: 2;
  margin-right: 1rem;
  padding: 20px;
  max-width: 400px;
  min-height: 300px;
}

.playerInfo {
  display: flex;
  align-items: center;

  img {
    margin-right: 12px;
  }
}

.text {
  // width: 400px;
  font-weight: 400;
  line-height: 170%;
  margin: 26px 0;
}

.company {
  color: rgba(38, 38, 38, 0.80);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.username {
  color: #262626;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
}

//start mobile
.mobileWhatOurRidersSay {
  width: 201px;

  .text {
    width: 400px;
    font-weight: 400;
  }
}