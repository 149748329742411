@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.container {
  @include column-center;
  width: 1450px;
  height: 1778px;
  flex-shrink: 0;
  padding: 40px 192px 0 160px;
  gap: 40px;
  color: rgba(38, 38, 38, 0.80);
  text-align: center;
  font-family: Cabin;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;

  @media (max-width: 1024px) {
    padding: 40px 5% 0 5%;
  }
  
  .titleContainer {
    width: 1010px;
    @include column;
    gap: 20px;
  }

  img {
    width: 183px;
    height: 362px;
   object-fit: fill;
  }

  .leftItemContainer {
    display: flex;
    justify-content: space-between;
    width: 70%;

    .informationContainer {
      display: flex;
      gap: 20px;
      width: 50%;

      .orderContainer {
        width: 60px;
        height: 60px;
        border: 1px solid #FAE95B;
        border-radius: 50%;
        @include center;
        background: #FAE95B;
      }

      .subInformationContainer {
        @include column;
        text-align: start;
        gap: 16px;
      }
    }
  }

  .rightContainer {
    display: flex;
    justify-content: space-between;
    width: 70%;

    .informationContainer {
      display: flex;
      gap: 20px;
      width: 50%;

      .orderContainer {
        width: 60px;
        height: 60px;
        border: 1px solid #FAE95B;
        border-radius: 50%;
        @include center;
        background: #FAE95B;
      }

      .subInformationContainer {
        @include column;
        align-items: flex-start;
        text-align: start;
        gap: 16px;
        width: 70%;


      }
    }
  }
}

//mobile start
.mobileContainer {
  @include column-center;
  align-content: center;
  gap: 24px;
  width: 90%;
  h1{
    font-size: 28px;
  }
  h3{
    font-size: 24px;
  }
  .titleContainer {
    @include column;
    align-items: center;
    text-align: center;
    width: 90%;
  }
  img{
    width: 128px;
  }

  .leftItemContainer {
    @include column-center;
    gap: 20px;
    width: 100%;
  }

  .rightContainer {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .rightContainer>.informationContainer {
    display: flex;
    gap: 20px;
    width: 90%;

    .orderContainer {
      width: 60px;
      height: 60px;
      border: 1px solid #FAE95B;
      border-radius: 50%;
      @include center;
      background: #FAE95B;
    }

    .subInformationContainer {
      @include column;
      align-items: flex-start;
      text-align: start;
      gap: 16px;
      width: 70%;


    }
  }
  .leftItemContainer>.informationContainer {
    display: flex;
    gap: 20px;
    width: 90%;

    .orderContainer {
      width: 60px;
      height: 60px;
      border: 1px solid #FAE95B;
      border-radius: 50%;
      @include center;
      background: #FAE95B;
    }

    .subInformationContainer {
      @include column;;
      text-align: start;
      gap: 16px;
    }
  }

}

//mobile end