@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.container {
 @include column-center;
  gap: 60px;
  width: 100%;
  padding: 140px 1px;
  background-color: #202632;
  border-radius: 100% 100% 100% 100% / 10%;

  .titleContainer {
    @include center;

    color: #F6F6F7;
    text-align: center;
    font-family: Cabin;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .items {
    @include center;
    gap: 32px;

    .item {
      width: 25%;
      max-width: 300px;
      padding: 24px 16px;
    @include column;
      align-items: center;
      text-align: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 20px;
      border: 1px solid var(--Light-Blue, #E8EFF5);
      background: #FFF;

      box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);
      img{
        width: 100px;
        height: 100px;
      }
    }
  }

  button {
    @include buttonStyle;
    width: 220px;
    height: 56px;
    background-color: $yellow;
  }
}

//start mobile
.mobileContainer {
  @include column-center;
  gap: 60px;
  width: 100%;
  padding: 140px 1px;
  background-color: #202632;
  border-radius: 100% 100% 100% 100% / 10%;

  .titleContainer {
    @include center;

    color: #F6F6F7;
    text-align: center;
    font-family: Cabin;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .items {
    @include column-center;
    gap: 32px;

    .item {
      width: 100%;
      max-width: 300px;
      padding: 24px 16px;
      @include column-center;
      text-align: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 20px;
      border: 1px solid var(--Light-Blue, #E8EFF5);
      background: #FFF;
      /* Shadow */
      box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);

      h4 {

      }
    }
  }

  button {
    @include buttonStyle;
    width: 220px;
    height: 56px;
    background-color: $yellow;
  }
}

// end mobile