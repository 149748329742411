@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.chargerTariffs {
  display: flex;
  justify-content: space-between;
  gap: 175px;
  padding: 10px 50px;
  width: 90%;
  select{
    margin-bottom: 20px;
    padding: 0 10px;
    height: 40px;
    width: 100px;
    border-radius: 7px;
    border-color: #D4D4D4;
    &:focus{
      border-color: #D4D4D4;
      outline: none;
    }
  }
  .section1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 60px;
    width: 36%;


  }

  .section2 {
    width: 50%;
    padding: 30px;
    @include column-center;
    background: #F6FBFF;
    .borderDotted {
      height: 40px;
      width: 0;
      border: 1px dashed rgba(189, 196, 201, 0.60);
      //border-style: dashed;

    }

    .item {
      display: flex;
      width: 450px;
      padding: 20px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 16px;
      border-radius: 4.8px;
      //background: var(--White, #FFF);
      box-shadow: 0px 1.2px 4.8px 0px rgba(0, 0, 0, 0.10);

      .firstRowContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

      }
    }
  }
}

//mobile start
.mobileChargerTariffs {
  @include column;
  align-items: center;
  width: 90%;
  gap: 30px;
  select{
    margin-bottom: 20px;
    padding: 0 10px;
    height: 35px;
    width: 100px;
    border-radius: 7px;
    border-color: #D4D4D4;
    &:focus{
      border-color: #D4D4D4;
      outline: none;
    }
  }
  .section1 {
    @include column;
    justify-content: center;
    text-align: center;
    gap: 60px;

    h1 {
      font-size: 28px;
    }

    .infoContainer {
      @include column;
      text-align: start;
      gap: 40px;

      .leftContainer {
        @include column;
        gap: 40px;

        .item {
          display: flex;
          gap: 32px;

          .numberContainer {
            width: 50px;
            height: 50px;
            @include center;
            border-radius: 50%;
            border: 1px solid black;
          }

          .subInfoContainer {
            @include column;
            gap: 16px;
          }
        }
      }

      .rightContainer {
        @include column;
        gap: 40px;

        .item {
          display: flex;
          gap: 32px;

          .numberContainer {
            width: 50px;
            height: 50px;
            @include center;
            border-radius: 50%;
            border: 1px solid black;
            //gap: 16px;
          }

          .subInfoContainer {
            @include column;
            gap: 16px;
          }
        }
      }

    }
  }

  .section2 {
    width: 90%;
    @include column;
    min-width: 270px;
    padding: 12px;
    align-items: center;
    gap: 9.6px;
    background: #F6FBFF;

    .borderDotted {
      margin: 0 auto;
      height: 24px;
      width: 0;
      border: 1px dashed rgba(189, 196, 201, 0.60);
    }

    .item {
      @include column;
      width: 90%;
      padding: 20px;
      justify-content: space-between;
      align-items: flex-start;
      gap: 16px;
      border-radius: 4.8px;
      background: var(--White, #FFF);
      box-shadow: 0px 1.2px 4.8px 0px rgba(0, 0, 0, 0.10);

      .firstRowContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

      }

    }

  }
}

// mobile end