@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";
.whyChooseMimoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 60px;



  .items {
    display: flex;
    justify-content: space-between;
    width: 80%;
    gap: 20px;

    .item {
      width: 30%;
      display: flex;
      gap: 20px;
      flex-direction: column;
      .itemImg {


        .img {
          width: 100px;
          height: 100px;
        }
      }
      .itemInformation {
        @include column;
        gap: 10px;



        .subItemInfo {
          display: flex;
          gap: 10px;
          .subItemTitle {
            display: flex;
          }
        }
        ul {
          @include column;
          gap: 10px;
          padding: 0 0 0 20px;

        }
      }
      }


    }
  }






//start mobile
.mobileWhyChooseMimoContainer {
  width: 100%;
 @include column-center;
  gap: 10px;

  .imgContainer {
    @include center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .items {
    @include column;
    gap: 20px;
    padding: 0 20px;

    .item {
      display: flex;
      gap: 20px;
      img{
        width: 60px;
        height: 60px;
      }

      ․itemInformation {
        @include column;
      }

      .subItemInfo {
        display: flex;
        gap: 20px;
      }
    }
  }

  ul {
    @include column;
    align-items: flex-start;
    gap: 10px;
    padding: 0 0 0 20px;


  }
}

//end Mobile


