.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 1px;
  right: 0;
  background: rgba(0, 0, 0, .8);
  z-index: 10;
  overflow: hidden;
}
.sidebar {
  padding: 30px;
  width: 30%;
  min-width: 350px;
  position: absolute;
  //top: 175px;
  //right:65px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%,-50%);
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 5px;

  .menu {
    font-size: 22px;
  }
}

.left {
  .sidebar {
    left:65px;
  }
}
.addProduct{
  .sidebar{
    background-color: #61dafb;
  }
}