@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.yourRidingBuddyContainer {
  @include column-center;
  gap: 60px;
  padding: 40px 192px 0 160px;
  color: $black;
  
  @media (max-width: 1024px) {
    padding: 40px 5% 0 5%;
  }

  .informationContainer {
    @include column-center;
    text-align: center;
    width: 60%;
    gap: 24px;
  }

  .container {
    @include center;
    gap: 24px;

    .item {
      width: 24%;
      height: 350px;
      min-height: 300px;
      padding: 24px;
     display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 14px;
      border-radius: 20px;
      border: 1px solid #E8EFF5;
      background: #FFF;
      box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);
      img{
        height: 100px;
      }
    }
  }
}

/// Mobile start ///
.mobileYourRidingBuddyContainer {
  padding: 40px 20px;


  .informationContainer {
    width: 100%;
    text-align: center;
    h1{
      font-size: 28px;
    }
    p{
      font-size: 18px;
    }
  }

  .container {
    flex-direction: column;
    .item {
      width: 100%;
    }
  }
}

/// Mobile end ///