@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.footerContainerDark {
  @include column;
  gap: 20px;
  background-color: $backGroundDarkColor;
  font-family: Cabin;
  font-style: normal;
  color: $colorWhite;
  padding: 0 192px 0 160px;
  height: 100%;
  margin-top: 20px;

  svg {
    color: $colorWhite;
  }

  a {
    color: $colorWhite;
  }

  .subFooter {
    padding: 40px 0 0;
  }
}

.footerContainerLight {
  background-color: #fff;

  svg, a, .copyrightContainer {
    color: rgba(32, 38, 50, 0.80);
  }

  .subFooter > .subFooterSubscribe > h4 {
    color: #202632;
  }

  .subFooter > .subFooterSubscribe > div {
    border-color: #D4D4D4;

    > input {
      color: #202632;
      background-color: #fff;
    }

  }

  color: #202632;
}


.subFooter {
  display: flex;
  justify-content: space-between;
  padding: 40px 192px 0 160px;

  @media (max-width: 1024px) {
    padding: 40px 5% 0 5%;
  }

  .subFooterInfo {
    @include column;
    gap: 20px;

    svg {
      margin-right: 4px;
    }

    a {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      list-style: none;
      text-decoration: none;
    }
  }

  .subFooterSubscribe {
    display: flex;
    flex-direction: column;
    gap: 20px;

    li {
      list-style: none;
    }

    h4 {
      color: $colorWhite;
      font-size: 24px;
      font-weight: 600;
      line-height: 22px;
    }

    & > div {
      width: 370px;
      height: 50px;
      display: flex;;
      gap: 50px;
      border: 1px solid rgba(255, 255, 255, 0.40);
      border-radius: 30px;
      padding: 3px 3px 3px 17px;

      button {
        background: $yellow;
        width: 30%;
        border-radius: 50px;


        @include center;
        flex: 1 0 0;
        color: $backGroundDarkColor;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 130%;
        border: none;
      }

      input {
        background-color: $backGroundDarkColor;
        border: 0;
      }

      input:focus {
        outline: none;
      }
    ;
    }
  }
}

.footerMenuContainer {
  width: 100%;
  @include center;
  gap: 24px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  cursor: pointer;
  border-top: 1px solid rgba(255, 255, 255, 0.10);
  padding-top: 20px;


  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }
}

.socialPages {
  width: 100%;
  @include center;
  gap: 24px;
  padding: 25px 0 15px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.copyrightContainer {
  @include center;
  color: rgba(246, 246, 247, 0.80);
  font-family: Cabin;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  padding: 24px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.10);
}


//start mobile
.mobileFooter {
  @include column;
  padding: 0;
  min-height: 800px;
  gap: 40px;

  .subFooter {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 0 0 22px;
    gap: 32px;

    .subFooterInfo {
      display: flex;
      gap: 30px;
    }

    .subFooterSubscribe {

      & > div {
        width: 300px;
        display: flex;
        gap: 20px;
      }

      h4 {
        font-size: 24px;
      }

      button {
        font-size: 14px;
      }
    }
  }

  .footerMenuContainer {
    @include column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    padding: 0 0 0 22px;
    gap: 10px;
  }

  .socialPages {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 0 0 22px;
  }
}

//end mobile


