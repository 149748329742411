.menu {
  width: 100%;
  display: flex;
  justify-content: space-between;

  height: 80px;
  font-family: Roboto;
  font-size: 28px;
  line-height: 27px
}
.container{
  display: flex;
  flex-direction: column;
  width: 100% ;
  padding: 0;
  .menuContainer{
    display: flex;
    padding: 10px 192px 10px 160px;

    @media (max-width: 1024px) {
      padding: 10px 5% 10px 5%;
    }
  }

}
.textContainer{
  padding: 0px 10% 0px 10%;
  @media (max-width: 1024px) {
    padding: 0px 5% 0px 5%;
  }
  font-size: 14px;
  color: #D4D4D4;
  width: 100% ;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#262626 ;
}
.dropDownMenu {
  display: none;
}

.dropDownMenuHolder {
  position: relative;
}

.arrowIcon:hover {
  position: relative;

  ~.dropDownMenu {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

.ul {
  list-style: none;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;

  img {
    position: absolute;
  }

  li {
    display: flex;
    list-style: none;
    margin: 0 16px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    width: max-content;
    cursor: pointer;

    a {
      text-decoration: none;
      color: var(--Dark, #262626);
    }
  }
}

.contactContainer {
  display: flex;
  justify-content: flex-end;
  width: 35%;

  a {
    list-style: none;
    text-decoration: none;
    color: var(--Dark, #262626);

    button {
      //color: var(--Dark, #262626);
      width: 160px;
      height: 48px;
      flex-shrink: 0;
      stroke-width: 1px;
      stroke: #404040;
      border-radius: 50px;
      border: 1px solid #404040;
      cursor: pointer;
      background: transparent;
    }
  }
}



.dropdown-menu {
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  transition: all linear 0.3s;
  position: absolute;
  top: 70px;

  ul {
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    li {
      padding: 14px;
      transition: all linear 0.3s;
      width: auto;
      padding: 12px 16px;
      width: 200px;
      border-bottom: 1px solid #F4F4F4;
      color: #262626;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
      margin: 0;
      &:hover {
        background: #F3F7FA;
        cursor: pointer;
      }
    }
  }
  

  

}


/// Mobile start ////
.mobileContainer{
  display: flex;
  flex-direction: column;

  .mobileHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5.5px 24px;
    width: 100%;
  }
  .textContainer{
    padding: 0 24px;
    color: #D4D4D4;
    width: 100% ;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#262626 ;

  }
}


.mobileMenu {
  width: 100%;
}
/// Mobile end ////