@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";
.container{
  width: 100%;
  display: flex;
  color: var(--Dark, #262626);
  font-family: Cabin;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 91.667% */
  .imgContainer{
    width: 50%;
   img{
     width: 100%;
     height: 500px;
   }
  }
  .infoContainer{
    @include column;
    justify-content: center;
    gap: 32px;
    .item{
      display: flex;
      align-items: center;
      gap: 32px;
      img{
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
    }
  }
}

//mobile start
.mobileContainer{
  width: 90%;
  @include column;
  align-items: center;
  color: var(--Dark, #262626);
  font-family: Cabin;
  .imgContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;

    img{
      width: 100%;
      height: 288.732px;
      flex-shrink: 0;

    }
  }
  .infoContainer{
    width: 90%;
    @include column-center;

    text-align: center;
    gap: 32px;
    h1{
      font-size: 28px;
    }
    .item{
      width: 100%;
      display: flex;
      align-items: center;
      text-align: start;
      gap: 32px;
      img{
        width: 20%;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
}
// mobile end