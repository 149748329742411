@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.partnerWithMimoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sendFormModal {
    @include column;
    gap: 50px;
    margin: 0 auto 0;
    //padding: 30px;
    color: var(--Dark, #262626);
    text-align: center;
    font-family: Cabin;
    font-size: 18px;
    font-style: normal;
    width: 100%;
    .modalTitle{
      .closeContainer {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
      }

    }


  }
  .item:nth-child(1) {
    position: relative;

    .subItemImg {
      position: absolute;
      // top: 147px;
      bottom: 0;
    }
  }

  .item:nth-child(2) {
    @include column;
    gap: 20px;

    .itemTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      img{
        height: 100%;
        object-fit: contain;
        min-height: 30px;
      }

      h1 {
        color: var(--Dark, #262626);
        text-align: center;
        font-family: Cabin;
        font-size: 44px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p {
        color: rgba(38, 38, 38, 0.80);
        text-align: center;
        font-family: Cabin;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        /* 30.6px */
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: center;

      button {
        width: 200px;
        height: 56px;
        border-radius: 50px;
        background: #FAE95B;
        border: 0;
      }
    }
  }

  .item:nth-child(3) {
    position: relative;
    top: 25px;
    width: 300px;

    .subItemImg {
      position: absolute;
      // top: 77px;
      right: 0;
    }
  }

  &:not(.mobilePartnerWithMimoContainer) {
    .item:nth-child(1),
    .item:nth-child(3) {
      .subItemImg {
        bottom: 0;

        >img {
          width: 32vw;
          min-width: 300px;
        }
      }
    }
  }
}

/// Mobile start ///
.mobilePartnerWithMimoContainer {
  position: relative;
  margin-bottom: 19rem;

  .item {
    padding: 20px 10px;

    >img {
      width: 50vw;

    }

    .subItemImg {
      img {
        width: 100%;
      }
    }
  }

  .item:nth-child(2) {
    @include column;
    gap: 20px;
    .modalTitle{
      width: 100%;
      h2{
        font-size: 18px;
      }
      .closeContainer {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
      }
    }
    .itemTitle {
      display: flex;
      gap: 20px;

      h1 {
        color: var(--Dark, #262626);
        text-align: center;
        font-family: Cabin;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p {
        color: rgba(38, 38, 38, 0.80);
        text-align: center;
        font-family: Cabin;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        /* 30.6px */
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: center;

      button {
        width: 200px;
        height: 56px;
        border-radius: 50px;
        background: #FAE95B;
        border: 0;
      }
    }
  }

  .item:nth-child(1) {
    width: 50%;
    position: absolute;
    top: 14.5rem;
    padding: 0;
  }

  .item:nth-child(3) {
    width: 50%;
    position: absolute;
    top: 15rem;
    padding: 0;
    left: 230px;
  }

  .item:nth-child(1) {
    left: 0;

    .subItemImg {
      position: absolute;
      top: 87px;
    }
  }

  .item:nth-child(3) {
    right: 0;
    left: unset;

    .subItemImg {
      position: absolute;
      top: 95px;
      right: 0px;
      width: 188px;
    }
  }
}

/// Mobile end   ///