@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.container {
  display: flex;
  background: #202632;
  color: #F6F6F7;
  font-family: Cabin;
  font-style: normal;
  width: 100%;


  .infoContainer {
    @include column;
    padding: 80px 218px 80px 130px;
    align-items: center;
    gap: 24px;
    width: 52%;

    @media (max-width: 1024px) {
      padding: 80px 5% 80px 5%;
    }
  }

  .imgContainer {
    width: 39%;

    img {
      width: 601px;
      height: 402px;
      flex-shrink: 0;
    }
  }
}

//mobile start
.mobileContainer {
  width: 90%;
  @include column-center;
  background: #202632;
  color: #F6F6F7;
  text-align: center;
  font-style: normal;
  gap: 20px;

  .infoContainer {
    width: 90%;
    @include column;
    gap: 20px;

    h1 {
      font-size: 28px;
    }
  }

  .imgContainer {
    width: 100%;

    img {
      width: 90%;
      flex-shrink: 0;
    }
  }
}

//Mobile end
