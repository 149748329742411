
@import url('https://fonts.googleapis.com/css2?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Cabin');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Cabin, Cabin, serif;
}

body {
  width: 100%;
}

button{
  font-family: Roboto;
}