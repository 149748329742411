@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.contactContainer {
  @include column-center;
  gap: 80px;

  .sendFormModal {
    @include column;
    gap: 50px;
    margin: 0 auto 0;
    //padding: 30px;
    color: var(--Dark, #262626);
    text-align: center;
    font-family: Cabin;
    font-size: 18px;
    font-style: normal;
    width: 100%;
.modalTitle{
  .closeContainer {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

}


  }

  .infoContainer {
    display: flex;
    justify-content: center;
    gap: 40px;

    .items {
      @include column;
      gap: 40px;

      .item {
        display: flex;
        width: 512px;
        padding: 32px 24px;
        align-items: center;
        gap: 24px;
        border-radius: 20px;
        border: 1px solid var(--Light-Blue, #E8EFF5);
        background: #FFF;
        /* Shadow */
        box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);

        .itemNumber {
          @include column-center;
          width: 60px;
          height: 60px;
          padding: 24px;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 60px;
          background: var(--brand-1, #FFEB3C);
        }
      }
    }
  }

  .btnContainer {
    button {
      width: 220px;
      height: 56px;
      border-radius: 50px;
      border: 1px solid #404040;

    }
  }
}

//mobile start
.mobileContactContainer {
  @include column-center;
  gap: 20px;
  padding: 0 15px;
  .modalTitle{
    width: 100%;
    h2{
      font-size: 18px;
    }
    .closeContainer {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
  h1 {
    font-size: 28px;
  }

  .infoContainer {
    @include column;
    justify-content: center;
    gap: 20px;

    .items {
      @include column;
      gap: 20px;

      .item {
        display: flex;
        padding: 32px 24px;
        align-items: center;
        gap: 24px;
        border-radius: 20px;
        border: 1px solid var(--Light-Blue, #E8EFF5);
        background: #FFF;
        /* Shadow */
        box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);

        .itemNumber {
          @include column-center;
          width: 60px;
          height: 60px;
          padding: 24px;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 60px;
          background: var(--brand-1, #FFEB3C);
        }
      }
    }
  }

  .btnContainer {
    button {
      width: 220px;
      height: 56px;
      border-radius: 50px;
      border: 1px solid #404040;

    }
  }
}

// mobile end