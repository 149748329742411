@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.joinOurFranchiseContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sendFormModal {
    @include column;
    gap: 50px;
    margin: 0 auto 0;
    //padding: 30px;
    color: var(--Dark, #262626);
    text-align: center;
    font-family: Cabin;
    font-size: 18px;
    font-style: normal;
    width: 100%;
    .modalTitle{
      .closeContainer {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
      }

    }


  }
  .item:nth-child(1) {
    position: relative;
    top: 138px;
    z-index: -1;

    .subItemImg {
      position: absolute;
      top: 147px;
    }
  }

  .item:nth-child(2) {
    @include column;
    gap: 44px;
    padding-top: 250px;


    .itemTitle {
      @include column;
      gap: 24px;

      h1 {
        color: var(--Dark, #262626);
        text-align: center;
        font-family: Cabin;
        font-size: 44px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p {
        color: rgba(38, 38, 38, 0.80);
        text-align: center;
        font-family: Cabin;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: center;

      button {
        width: 200px;
        height: 56px;
        border-radius: 50px;
        background: #FAE95B;
        border: 0;
      }
    }
  }

  .item:nth-child(3) {
    position: relative;
    top: 177px;
    z-index: -1;

    .subItemImg {
      position: absolute;
      top: 77px;
      right: 0;
    }
  }
  &:not(.joinOurFranchiseContainer) {
    .item:nth-child(1),
    .item:nth-child(3) {
      .subItemImg {
        bottom: 0;

        >img {
          width: 32vw;
          min-width: 300px;
        }
      }
    }
  }
}

/// Mobile start ////
.mobileJoinOurFranchiseContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 90px 0px;
  width: 100%;
  .modalTitle{
    width: 100%;
    h2{
      font-size: 18px;
    }
    .closeContainer {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }

  .item:nth-child(2) {
    position: absolute;
    padding: 20px 20px 0;
    top: 75px;
  }

  .item:nth-child(1),
  .item:nth-child(3) {
    top: 270px;

    .subItemImg {
      top: 25px;
    }
  }

  .item {
    img {
      width: 33vw;
    }

    .subItemImg {
      img {
        width: 42vw;
      }
    }
  }
}

.mobileMenu {
  width: 100%;
}

/// Mobile end ////