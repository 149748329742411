@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.downloadOurAppContainer {
  padding: 80px 192px 40px 160px;
  width: 100%;
  height: 100%;

  @media (max-width: 1024px) {
    padding: 40px 5% 0 5%;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    background-color: $yellow;
    border-radius: 40px;
    box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);
    // height: 400px;
    position: relative;
  }

  .informationContainer {
    width: 46%;
    @include column;
    gap: 24px;
    // padding: 106px 0 106px 120px;
    padding: 60px 0 60px 94px;

    .btnContainer {
      display: flex;
      gap: 10px;


      .button {
        height: 59px;
        width: 50%;
        border: 1px solid black;
        background-color: $yellow;
        @include center;
        border-radius: 56px;
        gap: 10px;
        a{
          text-decoration: none;
          color: black;
          @include center;
        }
        p {
          font-family: Roboto;
          font-size: 14px;
        }

        h5 {
          font-family: Roboto;
          font-size: 16px;

        }
      }
    }
  }

  .imageContainer {
    width: 50%;
    position: absolute;
    bottom: 0; //check
    right: 0;

    img {
      width:100%;
      height: 400px;
      object-fit: contain;
    }
  }
}

/// Mobile start ///
.mobileDownloadOurAppContainer {
  padding: 0;

  .container {
    flex-direction: column;
    height: auto;
  }

  .informationContainer {
    width: 100%;
    padding: 60px 20px 20px;
    display: flex;
    justify-content: center;

    h1 {
      font-size: 28px;
      display: flex;
      justify-content: center;
    }

    p {
      font-size: 18px;
    }

    .btnContainer {
      p {
        font-size: 14px;
      }

      h5 {
        font-size: 16px;
      }
    }
  }

  .imageContainer {
    position: relative;
    width: 100%;

    img {
      height: auto;
      width: 100%;
    }
  }
}

/// Mobile end  ///