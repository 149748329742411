@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";
.container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 73%;
  .leftContainer{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 50%;
    .firstRow{
      display: flex;
      gap: 32px;
      .numberContainer{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 1px solid var(--brand-tints-blue-1, #CAD7DF);
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }
    .secondRow{
      display: flex;
      gap: 32px;
      .numberContainer{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 1px solid var(--brand-tints-blue-1, #CAD7DF);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .subInfo{

      }
    }
  }
  .rightContainer{
    display: flex;
    padding: 80px 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F6FBFF;
    width: 50%;
    select{
      margin-bottom: 20px;
      padding: 0 10px;
      height: 40px;
      width: 100px;
      border-radius: 7px;
      border-color: #D4D4D4;
      &:focus{
        border-color: #D4D4D4;
        outline: none;
      }
    }
    .item{
      display: flex;
      width: 450px;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      border-radius: 8px;
      background: var(--White, #FFF);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
      .itemTitle{
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    .dotted{
      height: 40px;
      width: 0;
      border: 1px dashed rgba(189, 196, 201, 0.6);
      margin: 0 auto;
    }
  }
}
//mobile start
.mobileContainer{
  display: flex;
  flex-direction: column;

  width: 90%;
  gap: 40px;
  .leftContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    .firstRow{
      width: 100%;
      display: flex;
      gap: 32px;
      .numberContainer{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid var(--brand-tints-blue-1, #CAD7DF);
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }
    .secondRow{
      display: flex;
      gap: 32px;
      .numberContainer{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid var(--brand-tints-blue-1, #CAD7DF);
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }
  }
  .rightContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F6FBFF;
 padding: 10px;
    select{
      margin-bottom: 20px;
      padding: 0 10px;
      height: 40px;
      width: 100px;
      border-radius: 7px;
      border-color: #D4D4D4;
      &:focus{
        border-color: #D4D4D4;
        outline: none;
      }
    }
    .item{
      display: flex;
      width: 100%;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      border-radius: 8px;
      background: var(--White, #FFF);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
      .itemTitle{
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    .dotted{
      height: 40px;
      width: 0;
      border: 1px dashed rgba(189, 196, 201, 0.6);
      margin: 0 auto;
    }
  }
}
//mobile end