@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.chargerPlansContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 130px;
  gap: 180px;
  width: 100%;

  .section1 {
    @include column-center;
    background: #F6FBFF;
    width: 50%;
    padding: 20px;
    select{
      margin-bottom: 20px;
      padding: 0 10px;
      height: 40px;
      width: 100px;
      border-radius: 7px;
      border-color: #D4D4D4;
      &:focus{
        border-color: #D4D4D4;
        outline: none;
      }
    }
    .borderDotted {
      height: 40px;
      width: 0;
      border: 1px dashed rgba(189, 196, 201, 0.60);


    }

    .item {
      @include column;
      width: 450px;
      padding: 20px;
      justify-content: space-between;
      align-items: flex-start;
      gap: 16px;
      border-radius: 8px;
      background: var(--White, #FFF);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);

      .firstRowContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

      }
    }
  }
  .section2{
      @include column;
      align-items: flex-start;
      justify-content: center;
      gap: 30px;
    width: 33%;
  }

}


//mobile start
.mobileChargerPlansContainer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  width: 90%;
  gap: 30px;

  .section1 {
    @include column-center;
    background: #F6FBFF;
    padding: 20px;

    select{
      margin-bottom: 20px;
      padding: 0 10px;
      height: 40px;
      width: 100px;
      border-radius: 7px;
      border-color: #D4D4D4;
      &:focus{
        border-color: #D4D4D4;
        outline: none;
      }
    }
    .borderDotted {
      height: 40px;
      width: 0;
      border: 1px dashed rgba(189, 196, 201, 0.60);
    }

    .item {
      @include column;
      width: 270px;
      padding: 12px;
      gap: 9.6px;
      justify-content: space-between;
      align-items: flex-start;
      border-radius: 8px;
      background: var(--White, #FFF);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);

      .firstRowContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

      }
    }
  }

  .section2 {
    @include column-center;
    gap: 60px;
    text-align: center;

    h1 {
      font-size: 28px;
    }

    .infoContainer {
      display: flex;
      text-align: start;
      gap: 100px;

      .container {
        @include column;
        gap: 40px;

        .item {
          display: flex;
          gap: 32px;


          .numberContainer {
            width: 50px;
            height: 50px;
            @include center;
            border-radius: 50%;
            border: 1px solid black;
            //gap: 16px;
          }

          .subInfoContainer {
            @include column;
            gap: 16px;
          }
        }
      }

      .rightContainer {
        @include column;
        gap: 40px;

        .item {
          display: flex;
          gap: 32px;

          .numberContainer {
            width: 50px;
            height: 50px;
            @include center;
            border-radius: 50%;
            border: 1px solid black;
            //gap: 16px;
          }

          .subInfoContainer {
            @include column;
            gap: 16px;
          }
        }
      }
    }
  }

}

// mobile end