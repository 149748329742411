@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.container {
  @include column;
  align-items: center;
  gap: 60px;


  .items {
    @include column;
    gap: 24px;

    .firstRow {
      @include center;
      gap: 24px;

    }

    .secondRow {
      @include center;
      gap: 24px;
    }

    .item {
      @include column-center;
      height: 16vw;
      width: 16vw;
      padding: 24px;;
      gap: 24px;
      flex-shrink: 0;
      border-radius: 20px;
      border: 1px solid #E8EFF5;
      background: #FFF;

      /* Shadow */
      box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);

      img {
        height: 8vw;
      }
    }
  }

}

//mobile start
.mobileContainer {
  width: 90%;
  @include column-center;
  .titleContainer {

    text-align: center;
    h1{
      font-size: 28px;
    }
  }
  .items {
    .firstRow {
      @include center;
      flex-direction: column;
      gap: 24px;

    }

    .secondRow {
      @include column-center;
      gap: 24px;
    }

    .item {
      @include column-center;
      width: 200px;
      height: 200px;
      padding: 24px;
      gap: 24px;
      flex-shrink: 0;
      border-radius: 20px;
      border: 1px solid #E8EFF5;
      background: #FFF;

      /* Shadow */
      box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);
    }
  }
}

// mobile end