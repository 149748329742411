@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';
.container {
  display: flex;
  justify-content: space-between;
  width: 79%;
  gap: 10px;

  .infoContainer {
    width: 37%;
    @include column;
    justify-content: center;
    text-align: start;
    gap: 32px;

    .btnContainer {
      display: flex;
      gap: 10px;
      font-family: Roboto;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      .button {

        height: 59px;
        width: 220px;
        border-radius: 56px;
        border: 1px solid #262626;
        @include center;
        gap: 10px;
        a{
          text-decoration: none;
          color: black;
          @include center;
        }

        p {
          font-family: Roboto;
          font-size: 14px;
        }

        h5 {
          font-family: Roboto;
          font-size: 16px;

        }
      }
    }

  }

  .imgContainer {
    background: #F6FBFF;
    width: 40%;
    img{
      width: 100%;
    }
  }
}

//mobile start
.mobileContainer {
  @include column;
  align-items: center;
  gap: 24px;
  text-align: center;
  width: 100%;

  .infoContainer {
    @include column;
    align-items: center;
    width: 90%;
    gap: 24px;

    .btnContainer {
      @include column;
      gap: 10px;

      .button {
        @include center;
        height: 59px;
        padding: 12px 32px;
        gap: 10px;
        border-radius: 56px;
        border: 1px solid #262626;
        a{
          text-decoration: none;
          color: black;
          @include center;
        }

        p {
          font-family: Roboto;
          font-size: 14px;
        }

        h5 {
          font-family: Roboto;
          font-size: 16px;

        }
      }
    }

    h1 {
      font-size: 28px;
    }

    p {
      font-size: 18px;
    }
  }

  .imgContainer {
    width: 90%;
    background: #F6FBFF;


    img {
      min-width: 300px;
      width: 100%;
    }
  }
}

//mobile end