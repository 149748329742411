@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";
.container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 150px;
  padding-left: 100px;

  .infoContainer {
    @include column;
    gap: 60px;
    width: 40%;

    .items {
      display: flex;
      gap: 40px;

      .leftItem {
        @include column;
        gap: 40px;

        .item {
          display: flex;
          align-items: start;
          gap: 32px;

          .itemNumber {
           @include center;
            min-width: 40px;
            height: 40px;
            border-radius: 60px;
            border: 1px solid var(--brand-tints-blue-1, #CAD7DF);
          }

        }
      }

      .rightItem {
      @include column;
        gap: 40px;

        .item {
          display: flex;
          align-items: start;
          gap: 32px;

          .itemNumber {
            @include center;
            min-width: 40px;
            height: 40px;
            border-radius: 60px;
            border: 1px solid var(--brand-tints-blue-1, #CAD7DF);
          }
        }
      }
    }
  }

  .imgContainer {
    width: 40%;
    display: flex;
    justify-content: flex-end;

    img {
      width: 100%;
      height: 500px;
    }

  }
}

//start mobile
.mobileContainer {
  padding: 0 24px;

  .infoContainer {
   @include column;
    gap: 40px;
    text-align: center;
    //width: 40%;
    h1{
      font-size: 28px;
    }
    p{
      font-size: 18px;
    }

    .items {
     @include column;
      gap: 40px;

      .leftItem {
        @include column;
        text-align: start;
        gap: 40px;

        .item {
          display: flex;
          align-items: start;
          gap: 32px;

          .itemNumber {
           @include center;
            min-width: 40px;
            height: 40px;
            border-radius: 60px;
            border: 1px solid var(--brand-tints-blue-1, #CAD7DF);
          }

        }
      }

      .rightItem {
        @include column;
        text-align: start;
        gap: 40px;

        .item {
          display: flex;
          align-items: start;
          gap: 32px;

          .itemNumber {
            @include center;
            min-width: 40px;
            height: 40px;
            border-radius: 60px;
            border: 1px solid var(--brand-tints-blue-1, #CAD7DF);
          }
        }
      }
    }
  }


  .imgContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    img {
      width: 328.342px;
      height: 250px;
      flex-shrink: 0;
    }
  }

}

//end Mobile