@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  //padding: 50px;
  background-color: $yellow;


  .subContainer {
    width: 100%;
    @include column;
    align-items: center;
    gap: 20px;


    .titleContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      //justify-content: flex-start;
      gap: 10px;
      padding-right: 10px;
    }

    .section {
      display: flex;
      background-color: $yellow;
      width: 100%;
      align-items: center;
      padding: 30px 0 30px 0;
       .contactUsForm{
         display: flex;
         align-items: flex-start;
         flex-direction: column;
         gap: 10px;
         width: 47%;

       }
      .imgContainer {
        width: 50%;

        img {
          width: auto;
          height: 470px;
        }
      }

      .form {
        width: 100%;
        max-width: 654px;
        height: 409px;
        flex-shrink: 0;
        border-radius: 40px;
        border: 1px solid var(--Light-Blue, #E8EFF5);
        background: #FFF;

        /* Shadow */
        box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);

        .sendForm {
          @include column;
          gap: 120px;
          padding: 60px;

          .rows {
            @include column;
            gap: 32px;

            .firstRowItems {
              display: flex;
              gap: 60px;

              input {
                width: 50%;
                max-width: 236px;
                height: 30px;
                padding: 0 10px;
              }
            }

            .rowItems {
              @include column;
              gap: 32px;

              .btnContainer {
                display: flex;
                justify-content: center;

                button {
                  @include center;
                  max-width: 427.2px;
                  width: 100%;
                  height: 22.167px;
                  flex-shrink: 0;
                  color: #262626;
                  text-align: center;
                  font-family: Roboto;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  height: 40px;
                }
              }

            }
          }


          input {
            height: 35px;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 1px solid #D4D4D4;
            padding: 0 10px;

            &:focus {
              outline: 0;
            }
          }

          textarea {
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 1px solid #D4D4D4;
            padding: 0 10px;

            &:focus {
              outline: 0;
            }
          }

          button {
            height: 50px;
            background-color: $yellow;
            @include buttonStyle
          }
        }
      }
    }
  }


}

//mobile start
.mobileContainer {
  background-color: $yellow;
  @include column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;


  .subContainer {
    width: 100%;
    @include column;
    gap: 20px;

    .titleContainer {
      display: flex;
      justify-content: center;
      gap: 20px;

      h1 {
        font-size: 28px;
      }
    }

    .section {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      .contactUsForm{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .titleContainer{
          display: flex;
          flex-direction: column;
        }
      }
      .imgContainer {
        padding: 2px 5px;

        img {
          width: 100%;
        }
      }

      .form {
        flex-shrink: 0;
        border-radius: 40px;
        border: 1px solid var(--Light-Blue, #E8EFF5);
        background: #FFF;
        margin: 0 20px;

        /* Shadow */
        box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);

        .sendForm {
          @include column;
          gap: 20px;
          padding: 20px;

          .rows {
            @include column;
            gap: 20px;

            .firstRowItems {
              @include column;
              gap: 20px;


            }

            .rowItems {
              @include column;
              gap: 20px;

              .btnContainer {
                display: flex;
                justify-content: center;

                button {
                  @include center;
                  width: 90%;
                  height: 40px;
                  flex-shrink: 0;
                  color: #262626;
                  text-align: center;
                  font-family: Roboto;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  background-color: $yellow;
                  @include buttonStyle

                }
              }

            }
          }


          input {
            height: 35px;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 1px solid #D4D4D4;
            padding: 0 10px;

            &:focus {
              outline: 0;
            }
          }

          textarea {
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 1px solid #D4D4D4;
            padding: 0 10px;

            &:focus {
              outline: 0;
            }
          }

          //button {
          //  height: 50px;
          //  width: 90%;
          //  background-color: $yellow;
          //  @include buttonStyle
          //}
        }
      }
    }

  }

}

//mobile end