@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.container {
  @include column-center;
  gap: 60px;
  width: 100%;
  height: 806.5px;
  background-color: #202632;
  border-radius: 100% 100% 100% 100% / 10%;

  .titleContainer {
    @include center;
    color: #F6F6F7;
    text-align: center;
    font-family: Cabin;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    h1{
      font-size: 40px;
    }
  }

  .items {
    @include center;
    gap: 32px;

    .item {
      width: 20%;
      padding: 20px 16px;
      @include column-center;
      text-align: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 20px;
      border: 1px solid var(--Light-Blue, #E8EFF5);
      background: #FFF;
      /* Shadow */
      box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);
      img{
        width: 100px;
        height: 100px;
      }
    }
  }

  button {
    @include buttonStyle;
    width: 220px;
    height: 56px;
    background-color: $yellow;
  }
}
.sendFormModal {
  @include column;

  gap: 50px;
  margin: 0 auto 0;
  //padding: 30px;
  color: var(--Dark, #262626);
  text-align: center;
  font-family: Cabin;
  font-size: 18px;
  font-style: normal;
  width: 100%;
  .modalTitle{
    .closeContainer {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }

  }


}


//mobile start
.mobileContainer {
  @include column-center;
  background-color: #202632;
  gap: 20px;
  padding: 5px;

  .titleContainer {
    color: #F6F6F7;
    text-align: center;
    font-family: Cabin;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 39.2px */
    h1 {
      font-size: 24px;
    }
  }


  .items {
    @include column-center;
    gap: 20px;

    .item {
      @include column-center;
      height: 272px;
      padding: 24px 16px;
      gap: 20px;
      align-self: stretch;
      width: 350px;
      text-align: center;
      border-radius: 20px;
      border: 1px solid var(--Light-Blue, #E8EFF5);
      background: #FFF;
      box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);
      img{
        width: 60px;
        height: 60px;
      }
    }
  }

  .btnContainer {
    button {
      width: 177px;
      height: 21px;
      color: #262626;
      text-align: center;
      border-radius: 50px;
      background: #FAE95B;
      padding: 18px 20px 16px 22px;
      @include center;
    }
  }


}


//mobile end