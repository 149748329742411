@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";
.formContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  .rows{
    display: flex;
    flex-direction: column;
    gap: 32px;
    .firstRowItems {
      display: flex;

      gap: 60px;

      input {
        width: 42%;
        height: 30px;
        padding: 0 10px;
      }
    }

    .rowItems {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }


  input {
    height: 35px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #D4D4D4;
    padding: 0 10px;

    &:focus {
      outline: 0;
    }
  }

  textarea {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #D4D4D4;
    padding: 0 10px;

    &:focus {
      outline: 0;
    }
  }

  button {
    width: 100%;
    height: 50px;
    background-color: $yellow;
    @include buttonStyle
  }
}
// start mobile
.mobileFormContainer{
  width: 100%;
  @include column;
  gap: 60px;
  .rows{
    display: flex;
    flex-direction: column;
    gap: 32px;
    .firstRowItems {
      display: flex;
      flex-direction: column;
      gap: 32px;

      input {
        width: 100%;
        height: 30px;
        padding: 0 10px;
      }
    }

    .rowItems {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }


  input {
    height: 35px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #D4D4D4;
    padding: 0 10px;

    &:focus {
      outline: 0;
    }
  }

  textarea {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #D4D4D4;
    padding: 0 10px;

    &:focus {
      outline: 0;
    }
  }

  button {
    width: 100%;
    height: 50px;
    background-color: $yellow;
    @include buttonStyle
  }
}
// end mobile