@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
 select{
  padding: 0 10px;
   height: 40px;
   width: 100px;
   border-radius: 7px;
   border-color: #D4D4D4;
   &:focus{
     border-color: #D4D4D4;
     outline: none;
   }
 }
  .items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .item {
      width: 200px;
      display: flex;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 20px;
      border: 1px solid #E8EFF5;
      background: #F6FBFF;
      /* Shadow */
      box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);

      .numberContainer {
        width: 70px;
        height: 70px;
        border-radius: 70px;
        border: 1.167px solid var(--brand-tints-blue-1, #CAD7DF);
        @include center
      }
    }
  }
}

//mobile start
.mobileContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;

  select{
    padding: 0 10px;
    height: 40px;
    width: 100px;
    border-radius: 7px;
    border-color: #D4D4D4;
    &:focus{
      border-color: #D4D4D4;
      outline: none;
    }
  }
  .items {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;


    .item {
      width: 200px;
      display: flex;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 20px;
      border: 1px solid #E8EFF5;
      background: #FFF;
      /* Shadow */
      box-shadow: 0px 0px 53px 0px rgba(147, 164, 171, 0.10);

      .numberContainer {
        width: 70px;
        height: 70px;
        border-radius: 70px;
        border: 1.167px solid var(--brand-tints-blue-1, #CAD7DF);
        @include center
      }
    }
  }
}
//mobile end