@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.container {
@include column-center;
  width: 90%;
  padding: 40px 192px 0 160px;
  gap: 40px;
  color: rgba(38, 38, 38, 0.80);
  text-align: center;
  font-family: Cabin;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  margin-bottom: 60px;

  @media (max-width: 1024px) {
    padding: 40px 5% 0 5%;
  }
  
  .titleContainer {
    color: var(--Dark, #262626);
    width: 1010px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .itemContainerRight {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    //gap: 150px;
    img {
      width: 200px;
      height: 362px;
      flex-shrink: 0;
    }

    .informationContainer {
      display: flex;
      justify-content: flex-end;
      gap: 20px;

      .orderContainer {
        width: 60px;
        height: 60px;
        border: 1px solid #FAE95B;
        border-radius: 50%;
        @include center;
        background: #FAE95B;
      }

      .subInformationContainer {
        @include column;
        align-items: flex-start;
        text-align: left;
        gap: 16px;
        width: 70%;

      }
    }
  }

  .itemContainerLeft {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    //gap: 135px;



    img {
      width: 200px;
      height: 362px;
      flex-shrink: 0;
    }

    .informationContainer {
      display: flex;
      justify-content: flex-start;
      gap: 20px;

      .orderContainer {
        width: 60px;
        height: 60px;
        border: 1px solid #FAE95B;
        border-radius: 50%;
        @include center;
        background: #FAE95B;
      }

      .subInformationContainer {
        @include column;
        align-items: flex-start;
        text-align: left;
        gap: 16px;
        width: 70%;

        p {
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }
}

// Mobile start
.mobileContainer {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 90%;


  .titleContainer {
    width: 90%;

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 18px;
    }
  }

  .itemContainerRight {
    width: 90%;
    @include column;;
    align-items: center;
    gap: 30px;

    .informationContainer {
      width: 90%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;

      .orderContainer {
        width: 60px;
        height: 60px;
        border: 1px solid #FAE95B;
        border-radius: 50%;
        @include center;
        background: #FAE95B;
      }

      .subInformationContainer {
        @include column;
        align-items: flex-start;
        text-align: left;
        gap: 16px;
        width: 70%;

        p {
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }

  .itemContainerLeft {
    width: 90%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .informationContainer {
      width: 90%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      .orderContainer {
        width: 60px;
        height: 60px;
        border: 1px solid #FAE95B;
        border-radius: 50%;
        @include center;
        background: #FAE95B;
      }

      .subInformationContainer {
        @include column;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        gap: 16px;


      }
    }
  }



}

// Mobile end