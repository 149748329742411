@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.contactContainer {
  width: 100%;
  @include column-center;
  padding: 40px 192px 0 160px;
  background: #F6FBFF;
  font-family: Cabin, Cabin, serif;
  font-style: normal;

  @media (max-width: 1024px) {
    padding: 40px 5% 0 5%;
  }

  .informationContainer {
    width: 50%;
    height: 130px;
    @include column;
    align-items: center;
    gap: 24px;
  }

  .navContainer {
    width: 81%;
    height: 525px;
    border-radius: 40px;
    background: #FFF;
    color: #F6F6F7;
    box-shadow: 0 0 53px 0 rgba(147, 164, 171, 0.10);
    display: flex;
    padding: 5px;

    @media (max-width: 1024px) {
      width: 90%;
    }

    .navInformation {
      width: 44%;
      height: 494px;
      flex-shrink: 0;
      border-radius: 20px;
      background: #202632;
      display: flex;
      flex-direction: column;
      padding: 24px 32px 0 32px;
      gap: 35px;

      svg {
        margin: 0 5px;
      }
    }

    .sendForm {
      @include column;
      gap: 120px;
      margin: 80px auto 0;

      .rows {
        @include column;
        gap: 32px;

        .firstRowItems {
          display: flex;
          gap: 60px;

          input {
            width: 50%;
            height: 30px;
            padding: 0 10px;
          }
        }

        .rowItems {
          @include column;
          gap: 32px;
        }
      }


      input {
        height: 35px;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 1px solid #D4D4D4;
        padding: 0 10px;

        &:focus {
          outline: 0;
        }
      }

      textarea {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 1px solid #D4D4D4;
        padding: 0 10px;

        &:focus {
          outline: 0;
        }
      }

      button {
        height: 50px;
        background-color: $yellow;
        @include buttonStyle
      }
    }
  }
}


//start mobile
.mobileContactContainer {
  width: 100%;
  @include column-center;
  background: #F6FBFF;
  font-family: Cabin, Cabin, serif;
  font-style: normal;
  padding: 0 15px;
  gap: 20px;

  .informationContainer {
    width: 100%;
    @include column;
    align-items: center;
    text-align: center;
    gap: 25px;
    padding: 0 20px;

    h1 {
      font-size: 28px;
    }
  }

  .navContainer {
    width: 100%;
    border-radius: 17px;
    background: #FFF;
    color: #F6F6F7;
    box-shadow: 0 0 53px 0 rgba(147, 164, 171, 0.10);
    display: flex;
    flex-direction: column-reverse;
    padding: 5px;
    gap: 20px;

    .navInformation {
      width: 100%;
      flex-shrink: 0;
      border-radius: 20px;
      background: #202632;
      display: flex;
      flex-direction:column;
      padding: 24px 32px 24px 32px;
      gap: 20px;

      svg {
        margin: 0 5px;
      }
    }

    .sendForm {
      @include column;
      gap: 20px;

      .rows {
        @include column;
        gap: 20px;

        .firstRowItems {
          @include column;
          gap: 20px;


        }

        .rowItems {
          @include column;
          gap: 20px;
        }
      }


      input {
        width: 100%;
        height: 35px;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 1px solid #D4D4D4;
        padding: 0 10px;

        &:focus {
          outline: 0;
        }
      }

      textarea {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 1px solid #D4D4D4;
        padding: 0 10px;

        &:focus {
          outline: 0;
        }
      }

      button {
        height: 50px;
        background-color: $yellow;
        @include buttonStyle
      }
    }
  }

}

//end mobile