@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";
.container{
@include column-center;
  gap: 60px;
  .items{
    @include center;
    width: 100%;
    gap: 24px;
    // flex-wrap: wrap;

    .item{
      @include column-center;;
      width: 20%;
      height: 220px;
      min-width: 200px;
      max-width: 250px;
      padding: 0 24px;
      gap: 15px;
      border-radius: 20px;
      border: 1px solid #E8EFF5;
      background: #FFF;

      /* Shadow */
      box-shadow: 0 0 53px 0 rgba(147, 164, 171, 0.10);
      img{
        width: 100px;
        height: 100px;
      }
    }
  }

}
//start mobile
.mobileContainer{
 text-align: center;
  width: 90%;
  .items{
    @include column-center;
    width: 90%;
    gap: 24px;
    h1{
      font-size: 28px;
    }
    .item{
      @include column-center;
      width: 50%;
      min-width: 200px;
      height: 220px;
      padding: 24px;
      gap: 15px;
      border-radius: 20px;
      border: 1px solid #E8EFF5;
      background: #FFF;

      /* Shadow */
      box-shadow: 0 0 53px 0 rgba(147, 164, 171, 0.10);
      img{
        width: 100px;
        height: 100px;
      }
    }
  }
}
// end mobile