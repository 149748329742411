@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin column{
  display: flex;
  flex-direction: column;
}
@mixin column-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@mixin buttonStyle{
  text-align: center;
  font-family: Roboto, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
}