.mobileNavigation {
  position: fixed;
  top: 0;
  left: 0;
  background: #202632;
  color: white;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  gap: 24px;
  z-index: 100;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}