@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 180px;

  .infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    .itemsContainer {
      display: flex;
      gap: 30px;

      .items {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;

          img {
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }

  .imgContainer {
    width: 40%;
    display: flex;
    justify-content: flex-end;

    img {
      width: 500px;
      height: 500px;
    }

  }
}

//start mobile
.mobileContainer {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .itemsContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .items {
        display: flex;
        flex-direction: column;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;

          img {
            width: 60px;
            height: 60px;
          }
        }
      }
    }
    h1{
      font-size: 28px;
    }
    p{
      font-size: 18px;
    }


  }


  .imgContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    img {
      width: 328.342px;
      height: 250px;
      flex-shrink: 0;
    }
  }

}

//end Mobile